import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Loans from './pages/Loans';

const App: React.FC = () => (
    <div>

        {/* Define the application routes */}
        <Routes>
            {/* Home Page Route */}
            <Route path="/" element={<Home />} />

            {/* Dashboard Page Route */}
            <Route path="/dashboard" element={<Dashboard />} />

            {/* Loans Page Route */}
            <Route path="/loans" element={<Loans />} />
        </Routes>
    </div>
);

export default App;
