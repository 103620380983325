import React, {useEffect, useState, useRef} from 'react';
import logo from '../assets/loader.svg';
import HeaderLogo from '../assets/headerlogo.png';
import AppHeader from "../components/AppHeader";
import GradientBG from '../assets/header.svg';
import box1 from "../assets/box1.png";
import box2 from "../assets/box2.png";
import box3 from "../assets/box3.png";
import box4 from "../assets/box3.png";
import cmc from "../assets/cmc.svg";


const Dashboard: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isVisible, setIsVisible] = useState(true);

    // Define sectionRef and cardsRef
    const sectionRef = useRef<HTMLElement>(null);
    const cardsRef = useRef<Array<HTMLDivElement | null>>([]);

    useEffect(() => {
        const handleLoad = () => {
            setTimeout(() => {
                setIsLoading(false);
                setTimeout(() => setIsVisible(false), 500);
            }, 2000);
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    return (
        <div className="overflow-hidden">
            {/* Loader */}
            {isVisible && (
                <div
                    className={`fixed inset-0 flex items-center justify-center bg-white z-50 transition-opacity duration-500 ${
                        isLoading ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                    <img
                        src={logo}
                        alt="Loading..."
                        className="w-24 h-24 animate-spin"
                    />
                </div>
            )}

            {/* Dashboard Content */}
            {!isVisible && (
                <div>
                    <AppHeader/>
                    <div
                        className="mt-20 flex items-center justify-between rounded-lg "
                        style={{
                            backgroundImage: `url(${GradientBG})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className="container mx-auto relative px-4">
                            {/* Left Side: Text */}
                            <div>
                                <h1 className="text-white max-lg:py-14 max-lg:text-2xl py-32 text-6xl font-bold">Dashboard</h1>
                            </div>

                            {/* Right Side: SVG Icon */}
                            <div className="absolute z-40 right-0 w-[30%] top-[-5%]">
                                <img src={HeaderLogo} alt="Icon"/>
                            </div>
                        </div>
                    </div>

                    <section className="relative">
                        <div
                            style={{background: 'radial-gradient(circle, rgba(98,60,149,0.499) 0%, rgba(98,60,149,0.502) 50%, rgba(98,60,149,0) 100%)',}}
                            className="h-full   opacity-30 z-10 w-full absolute"></div>
                        <div className="container mx-auto relative z-20 pt-32 px-4">

                            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                                {/* Large Box on the Left */}

                                {/* Top Right Box */}
                                <div ref={(el) => (cardsRef.current[1] = el!)}
                                     className="relative  h-[18rem] md:col-span-1  rounded-3xl bg-gradient-to-t from-transparent to-white-300">


                                    <div
                                        className="relative h-full flex flex-col items-start justify-end z-10 pb-20 px-12">
                                        <a href="#" className="text-purple-600 text-3xl">
                                            <img src={cmc} alt="CMC" className="w-12 h-12 mb-10"/>
                                        </a>
                                        <p className="text-sm text-gray-600">
                                            Borrow APR
                                        </p>
                                        <h3 className="text-2xl font-bold text-gray-900">14.99%</h3>

                                    </div>
                                    <div style={{
                                        WebkitMaskImage:
                                            '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                                        WebkitMaskRepeat: 'no-repeat',
                                    }}
                                         className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                                </div>

                                {/* Top Right Box */}
                                <div ref={(el) => (cardsRef.current[1] = el!)}
                                     className="relative  h-[18rem] md:col-span-1  rounded-3xl bg-gradient-to-t from-transparent to-white-300">


                                    <div
                                        className="relative h-full flex flex-col items-start justify-end z-10 pb-20 px-12">
                                        <a href="#" className="text-purple-600 text-3xl">
                                            <img src={cmc} alt="CMC" className="w-12 h-12 mb-10"/>
                                        </a>
                                        <p className="text-sm text-gray-600">
                                            Borrow APR
                                        </p>
                                        <h3 className="text-2xl font-bold text-gray-900">14.99%</h3>

                                    </div>
                                    <div style={{
                                        WebkitMaskImage:
                                            '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                                        WebkitMaskRepeat: 'no-repeat',
                                    }}
                                         className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                                </div>

                                {/* Top Right Box */}
                                <div ref={(el) => (cardsRef.current[1] = el!)}
                                     className="relative  h-[18rem] md:col-span-1  rounded-3xl bg-gradient-to-t from-transparent to-white-300">


                                    <div
                                        className="relative h-full flex flex-col items-start justify-end z-10 pb-20 px-12">
                                        <a href="#" className="text-purple-600 text-3xl">
                                            <img src={cmc} alt="CMC" className="w-12 h-12 mb-10"/>
                                        </a>
                                        <p className="text-sm text-gray-600">
                                            Borrow APR
                                        </p>
                                        <h3 className="text-2xl font-bold text-gray-900">14.99%</h3>

                                    </div>
                                    <div style={{
                                        WebkitMaskImage:
                                            '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                                        WebkitMaskRepeat: 'no-repeat',
                                    }}
                                         className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                                </div>

                                {/* Top Right Box */}
                                <div ref={(el) => (cardsRef.current[1] = el!)}
                                     className="relative  h-[18rem] md:col-span-1  rounded-3xl bg-gradient-to-t from-transparent to-white-300">


                                    <div
                                        className="relative h-full flex flex-col items-start justify-end z-10 pb-20 px-12">
                                        <a href="#" className="text-purple-600 text-3xl">
                                            <img src={cmc} alt="CMC" className="w-12 h-12 mb-10"/>
                                        </a>
                                        <p className="text-sm text-gray-600">
                                            Borrow APR
                                        </p>
                                        <h3 className="text-2xl font-bold text-gray-900">14.99%</h3>

                                    </div>
                                    <div style={{
                                        WebkitMaskImage:
                                            '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                                        WebkitMaskRepeat: 'no-repeat',
                                    }}
                                         className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                                </div>
                                {/* Middle Right Box */}
                                <div ref={(el) => (cardsRef.current[1] = el!)}
                                     className="relative  h-[18rem] md:col-span-1  rounded-3xl bg-gradient-to-t from-transparent to-white-300">


                                    <div
                                        className="relative h-full flex flex-col items-start justify-end z-10 pb-20 px-12">
                                        <a href="#" className="text-purple-600 text-3xl">
                                            <img src={cmc} alt="CMC" className="w-12 h-12 mb-10"/>
                                        </a>
                                        <p className="text-sm text-gray-600">
                                            Borrow APR
                                        </p>
                                        <h3 className="text-2xl font-bold text-gray-900">14.99%</h3>

                                    </div>
                                    <div style={{
                                        WebkitMaskImage:
                                            '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                                        WebkitMaskRepeat: 'no-repeat',
                                    }}
                                         className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                                </div>
                                {/* Middle Right Box */}
                                <div ref={(el) => (cardsRef.current[1] = el!)}
                                     className="relative  h-[18rem] md:col-span-1  rounded-3xl bg-gradient-to-t from-transparent to-white-300">


                                    <div
                                        className="relative h-full flex flex-col items-start justify-end z-10 pb-20 px-12">
                                        <a href="#" className="text-purple-600 text-3xl">
                                            <img src={cmc} alt="CMC" className="w-12 h-12 mb-10"/>
                                        </a>
                                        <p className="text-sm text-gray-600">
                                            Borrow APR
                                        </p>
                                        <h3 className="text-2xl font-bold text-gray-900">14.99%</h3>

                                    </div>
                                    <div style={{
                                        WebkitMaskImage:
                                            '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                                        WebkitMaskRepeat: 'no-repeat',
                                    }}
                                         className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                                </div>

                                {/* Bottom Right Box */}
                                <div ref={(el) => (cardsRef.current[3] = el!)}
                                     className="relative  h-[16rem] md:col-span-2  rounded-3xl bg-gradient-to-t from-purple-300 to-purple-300">


                                    <div className="relative h-full flex flex-col items-start justify-end z-10 p-6">
                                        <h3 className="text-lg font-bold text-gray-900">Effortless Access</h3>
                                        <p className="text-sm text-gray-600">
                                            Seamlessly access diverse financial tools with unparalleled simplicity and
                                            transparency.
                                        </p>
                                    </div>
                                    <div style={{
                                        WebkitMaskImage:
                                            '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                                        WebkitMaskRepeat: 'no-repeat',
                                    }}
                                         className="absolute bg-mask-gradient inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="relative">
                        <div
                            style={{background: 'radial-gradient(circle, rgba(98,60,149,0.499) 0%, rgba(98,60,149,0.502) 50%, rgba(98,60,149,0) 100%)',}}
                            className="h-full opacity-30 z-10 w-full absolute"></div>
                        <div className="container mx-auto relative z-20 pt-32 px-4">

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                                {/* Large Box on the Left */}

                                {/* Top Right Box */}
                                <div ref={(el) => (cardsRef.current[1] = el!)}
                                     className="relative md:col-span-1  rounded-3xl bg-gradient-to-t from-transparent to-white-300">


                                    <div
                                        className="relative h-full flex flex-col items-start justify-end z-10 pb-20 px-12">
                                        <a href="#" className="text-purple-600 text-3xl">
                                            <img src={cmc} alt="CMC" className="w-12 h-12 mb-10"/>
                                        </a>
                                        <p className="text-sm text-gray-600">
                                            Borrow APR
                                        </p>
                                        <h3 className="text-2xl font-bold text-gray-900">14.99%</h3>

                                    </div>
                                    <div style={{
                                        WebkitMaskImage:
                                            '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                                        WebkitMaskRepeat: 'no-repeat',
                                    }}
                                         className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                                </div>

                                {/* Top Right Box */}
                                <div ref={(el) => (cardsRef.current[1] = el!)}
                                     className="relative  md:col-span-1  rounded-3xl bg-gradient-to-t from-transparent to-white-300">


                                    <div
                                        className="relative h-full flex flex-col items-start justify-end z-10 pb-20 px-12">
                                        <a href="#" className="text-purple-600 text-3xl">
                                            <img src={cmc} alt="CMC" className="w-12 h-12 mb-10"/>
                                        </a>
                                        <p className="text-sm text-gray-600">
                                            Borrow APR
                                        </p>
                                        <h3 className="text-2xl font-bold text-gray-900">14.99%</h3>

                                    </div>
                                    <div style={{
                                        WebkitMaskImage:
                                            '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                                        WebkitMaskRepeat: 'no-repeat',
                                    }}
                                         className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                                </div>

                                {/* Top Right Box */}
                                <div ref={(el) => (cardsRef.current[1] = el!)}
                                     className="relative  md:col-span-1  rounded-3xl bg-gradient-to-t from-transparent to-white-300">


                                    <div
                                        className="relative h-full flex flex-col items-start justify-end z-10 pb-20 px-12">
                                        <a href="#" className="text-purple-600 text-3xl">
                                            <img src={cmc} alt="CMC" className="w-12 h-12 mb-10"/>
                                        </a>
                                        <p className="text-sm text-gray-600">
                                            Borrow APR
                                        </p>
                                        <h3 className="text-2xl font-bold text-gray-900">14.99%</h3>

                                    </div>
                                    <div style={{
                                        WebkitMaskImage:
                                            '-webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)))',
                                        WebkitMaskRepeat: 'no-repeat',
                                    }}
                                         className="absolute inset-0 bg-white/30  border-2 border-purple-300 border-opacity-50 rounded-3xl"></div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
