import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import logo from '../assets/logo.svg';

const Header: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate(); // Initialize navigate

    const handleMenuClick = () => {
        setIsMenuOpen((prev) => !prev); // Toggle menu
    };

    const handleLinkClick = (id: string) => {
        setIsMenuOpen(false); // Close the menu
        const section = document.getElementById(id);
        if (section) {
            const targetPosition = section.getBoundingClientRect().top + window.scrollY;
            const startPosition = window.scrollY;
            const distance = targetPosition - startPosition;
            const duration = 500;
            let start: number | null = null;

            const animationStep = (timestamp: number) => {
                if (!start) start = timestamp;
                const progress = timestamp - start;
                const ease = progress / duration < 1 ? 1 - Math.pow(1 - progress / duration, 3) : 1;
                window.scrollTo(0, startPosition + distance * ease);

                if (progress < duration) {
                    window.requestAnimationFrame(animationStep);
                }
            };

            window.requestAnimationFrame(animationStep);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLaunchAppClick = () => {
        navigate('/dashboard'); // Redirect to the Dashboard page
    };

    return (
        <header
            className={`w-full fixed top-0 z-50 transition-all duration-300 ${
                isScrolled ? 'bg-white/70 backdrop-blur-md' : 'bg-transparent'
            }`}
        >
            <div className="container mx-auto flex justify-between items-center px-4 py-4">
                {/* Logo Section */}
                <img className="h-8" src={logo} alt="Logo" />

                {/* Desktop Navigation Links */}
                <nav className="space-x-6 hidden md:flex">
                    {[
                        { id: 'features', label: 'Features' },
                        { id: 'about', label: 'About', url: 'https://docs.kariru.xyz/' },
                        { id: 'howitwork', label: 'How to Kariru' },
                        { id: 'token', label: '$KRU' },
                    ].map((link) => (
                        <a
                            key={link.id}
                            href={link.url || `#${link.id}`}
                            className="text-gray-700 hover:text-purple-600"
                            onClick={(e) => {
                                if (!link.url) {
                                    e.preventDefault();
                                    handleLinkClick(link.id);
                                }
                            }}
                            target={link.url ? '_blank' : '_self'}
                            rel={link.url ? 'noopener noreferrer' : undefined}
                        >
                            {link.label}
                        </a>
                    ))}
                </nav>

                {/* Desktop Launch App Button */}
                <button
                   // Add onClick to redirect
                    className="hidden md:flex items-center justify-center px-6 py-3 bg-gray-100 rounded-2xl shadow-neumorphism hover:shadow-neumorphism-hover transition-shadow duration-300"
                >
                    <span className="text-purple-600 font-semibold">Launch App</span>
                </button>

                {/* Mobile Menu Button */}
                <button onClick={handleMenuClick} className="md:hidden text-purple-600">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                    </svg>
                </button>
            </div>

            {/* Mobile Menu */}
            <div
                className={`fixed top-0 left-0 w-full h-screen bg-white shadow-lg py-6 px-6 z-40 transition-transform duration-300 ease-in-out transform ${
                    isMenuOpen ? 'translate-y-0' : '-translate-y-full'
                }`}
            >
                {/* Close Button */}
                <div className="flex justify-between items-center mb-6">
                    <span className="text-lg font-bold text-gray-700">Menu</span>
                    <button onClick={handleMenuClick} className="text-purple-600">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                {/* Navigation Links */}
                <nav className="flex flex-col space-y-4">
                    {[
                        { id: 'features', label: 'Features' },
                        { id: 'about', label: 'About', url: 'https://docs.kariru.xyz/' },
                        { id: 'howitwork', label: 'How to Kariru' },
                        { id: 'token', label: '$KRU' },
                    ].map((link) => (
                        <a
                            key={link.id}
                            href={link.url || `#${link.id}`}
                            className="text-gray-700 hover:text-purple-600"
                            onClick={(e) => {
                                if (!link.url) {
                                    e.preventDefault();
                                    handleLinkClick(link.id);
                                }
                            }}
                            target={link.url ? '_blank' : '_self'}
                            rel={link.url ? 'noopener noreferrer' : undefined}
                        >
                            {link.label}
                        </a>
                    ))}
                </nav>
            </div>
        </header>
    );
};

export default Header;
