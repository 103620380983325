import React, { useEffect, useState } from 'react';
import HeroSection from '../components/HeroSection';
import CorePowersSection from '../components/CorePowersSection';
import IntroductionSection from '../components/IntroductionSection';
import HowItWorksSection from '../components/HowItWorksSection';
import TokenSection from '../components/TokenSection';
import KariruAdvantageSection from '../components/KariruAdvantageSection';
import JoinCommunitySection from '../components/JoinCommunitySection';
import Footer from '../components/Footer';
import logo from '../assets/loader.svg';
import Header from "../components/Header"; // Ensure this is the correct path

const Dashboard: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isVisible, setIsVisible] = useState(true); // State for visibility during smooth close

    useEffect(() => {
        const handleLoad = () => {
            setTimeout(() => {
                setIsLoading(false); // Hide the loader
                setTimeout(() => setIsVisible(false), 500); // Remove loader after transition
            }, 2000); // Optional delay for smooth transition
        };

        // Wait until all assets are fully loaded
        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        // Cleanup event listener
        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    return (
        <div className="overflow-hidden">
            {/* Loader */}
            {isVisible && (
                <div
                    className={`fixed inset-0 flex items-center justify-center bg-white z-50 transition-opacity duration-500 ${
                        isLoading ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                    <img
                        src={logo}
                        alt="Loading..."
                        className="w-24 h-24 animate-coin-spin"
                    />
                </div>
            )}

            {/* Main Content */}
            {!isVisible && (
                <>
                    <Header/>
                    <HeroSection />
                    <CorePowersSection />
                    <IntroductionSection />
                    <HowItWorksSection />
                    <TokenSection />
                    <KariruAdvantageSection />
                    <JoinCommunitySection />
                    <Footer />
                </>
            )}
        </div>
    );
};

export default Dashboard;
